import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { retrieveSurvey } from 'store/actions/surveys';
import { retrieveQuestion } from 'store/actions/questions';

class QuestionNumberHelpBlock extends React.Component {
  static propTypes = {
    surveyId: PropTypes.number.isRequired,
    retrieveSurvey: PropTypes.func.isRequired,
    retrieveQuestion: PropTypes.func.isRequired,
  };

  state = {
    questions: null,
  };

  async componentDidMount() {
    const survey = await this.props.retrieveSurvey(this.props.surveyId);
    const questionIds = survey.question_blocks.reduce(
      (accum, questionBlock) => accum.concat(questionBlock.questions),
      []
    );
    Promise.all(questionIds.map((questionId) => this.props.retrieveQuestion(questionId))).then(
      (questions) => this.setState({ questions: questions.map((q) => q.number) })
    );
  }

  render() {
    const { questions } = this.state;
    if (!questions || !questions.length) {
      return null;
    }
    questions.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
    return <span className="help-block">Question numbers {questions.join(', ')} taken.</span>;
  }
}

export default connect(
  null,
  { retrieveSurvey, retrieveQuestion }
)(QuestionNumberHelpBlock);

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';

import { Alert } from 'components';
import { questionBlockPropTypes } from 'helpers/proptypes';
import QuestionBlockForm from './QuestionBlockForm';

const QuestionBlockModal = ({ modalOpen, onModalClose, onFormSubmit, block, formType }) => {
  return (
    <Modal show={modalOpen} onHide={onModalClose} modalPrefix="bootstrap-modal modal">
      <Modal.Header closeButton>
        <Modal.Title>{`${formType} Question Block ${(block && block.title) || ''}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {['Add', 'Edit'].includes(formType) && (
          <QuestionBlockForm block={block} onFormSubmit={onFormSubmit} />
        )}
        {formType === 'Delete' && (
          <React.Fragment>
            <p>Are you sure you want to delete this question block?</p>
            {!!block.questions.length && (
              <Alert type="danger" icon="fa-exclamation-triangle">
                Cannot delete a question block with questions still associated with it.
              </Alert>
            )}
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        {formType === 'Delete' && (
          <button
            type="button"
            className="btn btn-danger"
            onClick={onFormSubmit}
            disabled={block.questions.length > 0}
          >
            Delete
          </button>
        )}
        <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
      </Modal.Footer>
    </Modal>
  );
};

QuestionBlockModal.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  block: questionBlockPropTypes,
  formType: PropTypes.string.isRequired,
};

export default QuestionBlockModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { object, string, number } from 'yup';
import { failedAssertion } from 'helpers/reporting';
import { questionBlockPropTypes } from 'helpers/proptypes';

const questionBlockSchema = object().shape({
  title: string().required(),
  intro: string(),
  order_id: number()
    .required()
    .positive()
    .integer(),
  survey: number()
    .positive()
    .integer(),
});

class QuestionBlockForm extends React.Component {
  static propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string,
    block: questionBlockPropTypes,
  };

  render() {
    const { block, onFormSubmit, submitButtonText } = this.props;
    return (
      <Formik
        initialValues={{
          title: block.title,
          intro: block.intro,
          order_id: block.order_id,
          survey: block.survey,
        }}
        validationSchema={questionBlockSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          try {
            await onFormSubmit(values);
          } catch (error) {
            setSubmitting(false);
            window.show_stack_topleft('Error', 'Something went wrong.', 'error');
            failedAssertion('Question block could not be saved', { error });
          }
          setSubmitting(false);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={`form-group ${touched.title && errors.title ? 'has-error' : ''}`}>
              <label htmlFor="title">Block Title</label>
              <input
                type="text"
                name="title"
                id="title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                className="form-control"
              />
              {touched.title && errors.title && <span className="help-block">{errors.title}</span>}
            </div>
            <div className={`form-group ${touched.intro && errors.intro ? 'has-error' : ''}`}>
              <label htmlFor="intro">Block Intro</label>
              <input
                type="text"
                name="intro"
                id="intro"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.intro}
                className="form-control"
              />
              {touched.intro && errors.intro && <span className="help-block">{errors.intro}</span>}
            </div>
            <div className={`form-group ${touched.order_id && errors.order_id ? 'has-error' : ''}`}>
              <label htmlFor="order_id">Block Order Number</label>
              <input
                type="number"
                name="order_id"
                id="order_id"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.order_id}
                className="form-control"
              />
              {touched.order_id && errors.order_id && (
                <span className="help-block">{errors.order_id}</span>
              )}
            </div>
            <button className="btn btn-success" type="submit" disabled={isSubmitting}>
              {submitButtonText || 'Save'}
            </button>
          </form>
        )}
      />
    );
  }
}

export default QuestionBlockForm;

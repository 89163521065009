import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import set from 'lodash/set';
import { Formik } from 'formik';
import { mixed, object, string, number, array } from 'yup';
import { RadioGroup, Radio } from 'react-radio-group';
import { questionPropTypes } from 'helpers/proptypes';
import { QuestionTypeDict, QuestionTypeLabels } from 'helpers/dictionaries';
import QuestionBlockSelector from './QuestionBlockSelector';
import QuestionNumberHelpBlock from './QuestionNumberHelpBlock';
import { EditChoiceMetadata } from './EditChoiceMetadata';
import { EditChoiceMatrixMetadata } from './EditChoiceMatrixMetadata';

const questionSchema = object().shape({
  number: number()
    .required()
    .positive()
    .integer(),
  question_text: string()
    .trim()
    .required()
    .max(254),
  question_type: mixed()
    .oneOf(Object.values(QuestionTypeDict))
    .required(),
  question_block: number()
    .integer()
    .positive()
    .required(),
  metadata: object().shape({
    choices: array().of(
      object().shape({
        text: string().required(),
        value: string().required(),
      })
    ),
    choiceMatrix: object().shape({
      choices: array().of(
        object().shape({
          text: string()
            .required()
            .min(1),
          value: string()
            .required()
            .min(1),
        })
      ),
      labels: array().of(string()),
    }),
  }),
  survey: number()
    .integer()
    .positive(),
});

class QuestionForm extends React.Component {
  static propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string,
    question: questionPropTypes.isRequired,
  };

  handleMetadataNew = (metadataKey, metadata, setFieldValue) => {
    if (metadataKey.includes('choiceMatrix')) {
      let value = get(metadata, metadataKey);
      if (!value) {
        if (metadataKey.includes('choices')) {
          set(metadata, metadataKey, [{ text: '', value: '' }]);
        }
        if (metadataKey.includes('labels')) {
          set(metadata, metadataKey, ['']);
        }
      } else {
        if (metadataKey.includes('choices')) {
          value.push({ text: '', value: '' });
        }
        if (metadataKey.includes('labels')) {
          value.push('');
        }
      }
    } else {
      if (!metadata[metadataKey]) {
        metadata[metadataKey] = [{ text: '', value: '' }];
      } else {
        metadata[metadataKey].push({ text: '', value: '' });
      }
    }
    setFieldValue('metadata', metadata);
  };

  handleMetadataChange = (e, metadataKey, metadata, setFieldValue) => {
    set(metadata, metadataKey, e.target.value);
    setFieldValue('metadata', metadata);
  };

  handleMetadataDelete = ([idx, metadataKey], metadata, setFieldValue) => {
    const field = get(metadata, metadataKey);
    field.splice(idx, 1);
    set(metadata, metadataKey, field);
    setFieldValue('metadata', metadata);
  };

  render() {
    const { question, onFormSubmit, submitButtonText } = this.props;
    return (
      <Formik
        initialValues={{
          number: question.number,
          question_text: question.question_text,
          question_type: question.question_type,
          question_block: question.question_block,
          metadata: question.metadata || {},
          survey: question.survey,
        }}
        validationSchema={questionSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          try {
            await onFormSubmit(values);
          } catch (err) {
            setSubmitting(false);
            window.show_stack_topleft('Error', 'The edits failed to save.', 'error');
          }
          setSubmitting(false);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={`form-group ${touched.number && errors.number ? 'has-error' : ''}`}>
              <label htmlFor="number">Question Number</label>
              <input
                type="number"
                name="number"
                id="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.number}
                className="form-control"
              />
              <QuestionNumberHelpBlock surveyId={question.survey} />
              {touched.number && errors.number && (
                <span className="help-block">{errors.number}</span>
              )}
            </div>
            <div
              className={`form-group ${
                touched.question_text && errors.question_text ? 'has-error' : ''
              }`}
            >
              <label htmlFor="question_text">Question Text</label>
              <input
                type="text"
                name="question_text"
                id="question_text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.question_text}
                className="form-control"
              />
              {touched.question_text && errors.question_text && (
                <span className="help-block">{errors.question_text}</span>
              )}
            </div>
            <div
              className={`form-group ${
                touched.question_block && errors.question_block ? 'has-error' : ''
              }`}
            >
              <label htmlFor="question_block" className="label-control">
                Block
              </label>
              <QuestionBlockSelector
                name="question_block"
                surveyId={question.survey}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.question_block}
              />
              {touched.question_block && errors.question_block && (
                <span className="help-block">{errors.question_block}</span>
              )}
            </div>
            <div
              className={`form-group ${
                touched.question_type && errors.question_type ? 'has-error' : ''
              }`}
            >
              <label htmlFor="question_type" className="label-control">
                Question Type
              </label>
              <RadioGroup name="question_type" selectedValue={values.question_type}>
                {Object.entries(QuestionTypeLabels).map(([value, label]) => (
                  <div className="radio" key={value}>
                    <label>
                      <Radio value={value} onChange={handleChange} onBlur={handleBlur} />
                      {label}
                    </label>
                  </div>
                ))}
              </RadioGroup>
              {touched.question_type && errors.question_type && (
                <span className="help-block">{errors.question_type}</span>
              )}
            </div>
            <div className="form-group">
              <label className="label-control">Metadata</label>
              {(!values.metadata || Object.keys(values.metadata).length === 0) && (
                <span className="help-block">No metadata associated with this question.</span>
              )}
              {[
                QuestionTypeDict.CHOICE,
                QuestionTypeDict.CHOICE_FREEFORM,
                QuestionTypeDict.CHOICE_MULTIPLE,
                QuestionTypeDict.CHOICE_MULTIPLE_FREEFORM,
              ].includes(values.question_type) ? (
                <EditChoiceMetadata
                  metadata={values.metadata}
                  errors={errors}
                  handleMetadataNew={this.handleMetadataNew}
                  handleMetadataChange={this.handleMetadataChange}
                  handleMetadataDelete={this.handleMetadataDelete}
                  setFieldValue={setFieldValue}
                />
              ) : values.question_type === QuestionTypeDict.CHOICE_MATRIX ? (
                <EditChoiceMatrixMetadata
                  metadata={values.metadata}
                  errors={errors}
                  handleMetadataNew={this.handleMetadataNew}
                  handleMetadataChange={this.handleMetadataChange}
                  handleMetadataDelete={this.handleMetadataDelete}
                  setFieldValue={setFieldValue}
                />
              ) : null}
            </div>

            <button className="btn btn-success" type="submit" disabled={isSubmitting}>
              {submitButtonText || 'Save'}
            </button>
          </form>
        )}
      />
    );
  }
}

export default QuestionForm;

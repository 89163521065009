import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { surveyPropTypes } from 'helpers/proptypes';
import SurveyForm from './SurveyForm';

const SurveyModal = ({ modalOpen, onModalClose, onFormSubmit, survey }) => {
  return (
    <Modal show={modalOpen} onHide={onModalClose} modalPrefix="bootstrap-modal modal">
      <Modal.Header closeButton>
        <Modal.Title>Edit Survey</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SurveyForm survey={survey} onFormSubmit={onFormSubmit} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
      </Modal.Footer>
    </Modal>
  );
};

SurveyModal.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  survey: surveyPropTypes,
};

export default SurveyModal;

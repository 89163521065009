import React from 'react';
import PropTypes from 'prop-types';
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
// import { SingleDatePicker } from 'react-dates';
import { Formik } from 'formik';
import { object, string, number, date } from 'yup';
import { Events } from 'api';
import { surveyPropTypes } from 'helpers/proptypes';
import { failedAssertion } from 'helpers/reporting';
import { EventLabels } from 'helpers/dictionaries';
import EventSelector from './EventSelector';

const { EVENT_TYPES, PUB_STATUS } = EventLabels;

const surveySchema = object().shape({
  name: string().required(),
  event: number()
    .positive()
    .integer()
    .nullable(),
  template: string().nullable(),
  start_dt: date().nullable(),
  end_dt: date().nullable(),
  publication_status: string().required(),
});

class SurveyForm extends React.Component {
  static propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string,
    survey: surveyPropTypes,
  };

  state = {
    events: [],
  };

  componentDidMount() {
    Events.list().then((events) => {
      this.setState({ events });
    });
  }

  render() {
    const { survey, onFormSubmit, submitButtonText } = this.props;
    return (
      <Formik
        initialValues={{
          name: survey.name,
          event: survey.event && survey.event.id,
          template: survey.template,
          start_dt: survey.start_dt,
          end_dt: survey.end_dt,
          publication_status: survey.publication_status,
        }}
        validationSchema={surveySchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await onFormSubmit(values);
          } catch (err) {
            window.show_stack_topleft('Error', 'Something went wrong.', 'error');
            failedAssertion('Something went wrong saving the survey form', { error: err });
          }
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={`form-group ${touched.name && errors.name ? 'has-error' : ''}`}>
              <label htmlFor="name">Survey Name</label>
              <input
                type="text"
                name="name"
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                className="form-control"
              />
              {touched.name && errors.name && <span className="help-block">{errors.name}</span>}
            </div>
            <div className={`form-group ${touched.event && errors.event ? 'has-error' : ''}`}>
              <label htmlFor="name">Event</label>
              <EventSelector
                value={values.event}
                name="event"
                events={this.state.events}
                onChange={(value) => setFieldValue('event', value)}
                dropdownStyle={{ zIndex: '1100' }}
              />
              {touched.event && errors.event && <span className="help-block">{errors.event}</span>}
            </div>
            <div className={`form-group ${touched.template && errors.template ? 'has-error' : ''}`}>
              <label htmlFor="template">Template</label>
              <select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.template}
                className="form-control"
                name="template"
              >
                <option value="">-------</option>
                {Object.entries(EVENT_TYPES).map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              {touched.template && errors.template && (
                <span className="help-block">{errors.template}</span>
              )}
            </div>
            <div
              className={`form-group ${
                touched.publication_status && errors.publication_status ? 'has-error' : ''
              }`}
            >
              <label htmlFor="publication_status">Publication Status</label>
              <select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.publication_status}
                className="form-control"
                name="publication_status"
              >
                {Object.entries(PUB_STATUS).map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              {touched.publication_status && errors.publication_status && (
                <span className="help-block">{errors.publication_status}</span>
              )}
            </div>
            {/* <div className={`form-group ${touched.start_dt && errors.start_dt ? 'has-error' : ''}`}>
              <label htmlFor="start_dt">Survey Start Date</label>
              <SingleDatePicker
                date={values.start_dt}
                onDateChange={(date) => setFieldValue('start_dt', date.setHours(6, 0, 0, 0))}
                isOutsideRange={() => false}
              />
              {touched.start_dt &&
                errors.start_dt && <span className="help-block">{errors.start_dt}</span>}
            </div>
            <div className={`form-group ${touched.end_dt && errors.end_dt ? 'has-error' : ''}`}>
              <label htmlFor="end_dt">Survey End Date</label>
              <SingleDatePicker
                date={values.end_dt}
                onDateChange={(date) => setFieldValue('end_dt', date.setHours(21, 0, 0, 0))}
                isOutsideRange={() => false}
              />
              {touched.end_dt &&
                errors.end_dt && <span className="help-block">{errors.end_dt}</span>}
            </div> */}
            <button className="btn btn-success" type="submit" disabled={isSubmitting}>
              {submitButtonText || 'Save'}
            </button>
          </form>
        )}
      />
    );
  }
}

export default SurveyForm;

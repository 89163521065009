import React from 'react';
import PropTypes from 'prop-types';
import Select, { Option, OptGroup } from 'rc-select';
import 'rc-select/assets/index.css';

const dropdownMenuStyle = {
  maxHeight: 500,
  overflow: 'auto',
};

const EventSelector = (props) => {
  const events = props.events || [];
  const optGroups = events.reduce((acc, event) => {
    const yearSeason = `${event.academic_year} ${event.academic_season}`;
    if (!acc[yearSeason]) {
      acc[yearSeason] = [];
    }
    acc[yearSeason] = [...acc[yearSeason], [event.id, event.title]];
    return acc;
  }, {});
  const children = Object.keys(optGroups).map((yearSeason) => (
    <OptGroup label={<span className="bold">{yearSeason}</span>} key={yearSeason}>
      {optGroups[yearSeason].map((event) => (
        <Option key={event[0]} value={event[0]} lowercaseName={event[1].toLowerCase()}>
          {event[1]}
        </Option>
      ))}
    </OptGroup>
  ));
  return (
    <Select
      value={props.value}
      dropdownMenuStyle={dropdownMenuStyle}
      dropdownStyle={props.dropdownStyle}
      style={{ width: '100%' }}
      optionFilterProp="lowercaseName"
      optionLabelProp="children"
      placeholder="Select event..."
      notFoundContent="No events found"
      onChange={props.onChange}
      onBlur={props.onBlur}
      name={props.name}
    >
      {children}
    </Select>
  );
};

EventSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  events: PropTypes.array,
  dropdownStyle: PropTypes.object,
};

export default EventSelector;

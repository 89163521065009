import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { questionPropTypes } from 'helpers/proptypes';
import QuestionForm from './QuestionForm';

const QuestionModal = ({ modalOpen, onModalClose, onFormSubmit, question, formType }) => {
  return (
    <Modal show={modalOpen} onHide={onModalClose} modalPrefix="bootstrap-modal modal" large>
      <Modal.Header closeButton>
        <Modal.Title>
          {formType} Question {question && question.number ? `#${question.number}` : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {['Add', 'Edit'].includes(formType) && (
          <QuestionForm question={question} onFormSubmit={onFormSubmit} />
        )}
        {formType === 'Delete' && <p>Are you sure you want to delete this question?</p>}
      </Modal.Body>
      <Modal.Footer>
        {formType === 'Delete' && (
          <button type="button" className="btn btn-danger" onClick={onFormSubmit}>
            Delete
          </button>
        )}
        <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
      </Modal.Footer>
    </Modal>
  );
};

QuestionModal.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  formType: PropTypes.string.isRequired,
  question: questionPropTypes,
};

export default QuestionModal;

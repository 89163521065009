import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { surveyPropTypes, loadingOrErrorPropTypes } from 'helpers/proptypes';
import { PageHeader } from 'components';

import SurveyAdminDropdownButton from 'components/shared/SurveyAdminDropdownButton';
import SurveyStatus from './SurveyStatus';

const SurveyAdminPageHeader = ({ survey, children }) => (
  <PageHeader.Basic
    title={children}
    subtitle={survey.name}
    actions={<SurveyAdminDropdownButton id={survey.id} />}
    subtext={
      <>
        <span className="icon-space-r">
          <SurveyStatus survey={survey} />
        </span>
        <span className="fa fa-calendar icon-space-r" />
        {survey.event ? (
          <Link to={window.URLS['pd:event_detail'](survey.event.id)}>{survey.event.title}</Link>
        ) : (
          'No event assigned'
        )}
      </>
    }
  />
);

SurveyAdminPageHeader.propTypes = {
  children: PropTypes.any,
  survey: PropTypes.oneOfType([surveyPropTypes, loadingOrErrorPropTypes]),
};

export default SurveyAdminPageHeader;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { EventDictionary, EventLabels } from 'helpers/dictionaries';
import { surveyPropTypes } from 'helpers/proptypes';

const { PUB_STATUS, EVENT_TYPES } = EventLabels;

const formatDate = (date) => date.format('MMM D, YYYY');

const SurveyBadge = ({ survey, statusOnly }) => {
  if (survey.template) {
    return (
      <React.Fragment>
        <span className="label label-primary">Template</span>
        {!statusOnly && ` Template for: ${EVENT_TYPES[survey.template]}`}
      </React.Fragment>
    );
  }
  if (survey.publication_status === EventDictionary.PUB_STATUS.DRAFT) {
    return <span className="label label-default">{PUB_STATUS[survey.publication_status]}</span>;
  }

  const now = moment();
  const start = moment(survey.start);
  const end = moment(survey.end);

  let status, className, text;
  if (!start || !end) {
    className = 'success';
    status = 'Open';
  } else if (now.isAfter(end)) {
    className = 'text-danger';
    status = 'Closed';
    text = `${formatDate(end)}`;
  } else if (now.isAfter(start) && now.isBefore(end)) {
    className = 'text-success';
    status = 'Open';
    text = `ends on ${formatDate(end)}`;
  } else if (now.isBefore(start)) {
    className = 'text-info';
    status = 'Pending';
    text = `opens ${formatDate(start)}`;
  } else {
    className = 'text-success';
    status = 'Open';
    text = 'no end date';
  }

  if (statusOnly) {
    return (
      <span className={className}>
        <strong className={className}>{status}</strong>
      </span>
    );
  }

  return (
    <span className={className}>
      <strong className={className}>{status}</strong> {text}
    </span>
  );
};

SurveyBadge.propTypes = {
  survey: surveyPropTypes.isRequired,
  statusOnly: PropTypes.bool,
};

export default SurveyBadge;

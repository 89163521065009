import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { failedAssertion } from 'helpers/reporting';
import { surveyPropTypes, loadingOrErrorPropTypes } from 'helpers/proptypes';
import { Alert, Loading } from 'components';
import { Question, QuestionBlock as Block, notifySuccess, notifyError } from 'api';
import {
  surveyDetailThunk,
  updateSurveyThunk,
  surveyQuestionBlockAdded,
} from 'store/actions/surveys';
import { questionDetailSuccess, retrieveQuestion, deleteQuestion } from 'store/actions/questions';
import {
  questionBlockDetail,
  questionBlockDetailSuccess,
  retrieveQuestionBlock,
  deleteQuestionBlock,
  questionBlockQuestionAdded,
  questionBlockQuestionMoved,
} from 'store/actions/question-blocks';
import { makeSurveyDetailSelector } from 'store/reducers/surveys';

import SurveyAdminPageHeader from '../SurveyAdminPageHeader';

import QuestionBlock from './QuestionBlock';
import SurveyModal from './SurveyModal';
import QuestionModal from './QuestionModal';
import QuestionBlockModal from './QuestionBlockModal';

export class SurveyAdminDetailPage extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    survey: PropTypes.oneOfType([surveyPropTypes, loadingOrErrorPropTypes]),
    surveyDetailThunk: PropTypes.func.isRequired,
    updateSurveyThunk: PropTypes.func.isRequired,
    retrieveQuestion: PropTypes.func.isRequired,
    deleteQuestion: PropTypes.func.isRequired,
    retrieveQuestionBlock: PropTypes.func.isRequired,
    deleteQuestionBlock: PropTypes.func.isRequired,
    questionBlockDetail: PropTypes.func.isRequired,
    questionDetailSuccess: PropTypes.func.isRequired,
    questionBlockDetailSuccess: PropTypes.func.isRequired,
    questionBlockQuestionMoved: PropTypes.func.isRequired,
    questionBlockQuestionAdded: PropTypes.func.isRequired,
    surveyQuestionBlockAdded: PropTypes.func.isRequired,
  };

  state = {
    questionModalOpen: false,
    blockModalOpen: false,
    surveyModalOpen: false,
    block: null,
    survey: null,
    blockActionType: '',
    questionActionType: '',
  };

  componentDidMount() {
    this.props.surveyDetailThunk(this.props.match.params.surveyId);
  }

  handleQuestionActionClick = async (questionId = null, questionActionType) => {
    let { question } = this.state;
    if (questionId) {
      question = await this.props.retrieveQuestion(questionId);
    } else {
      question = { survey: this.props.survey.id };
    }
    this.setState({ questionModalOpen: true, question, questionActionType });
  };

  handleBlockActionClick = async (blockId = null, blockActionType) => {
    let { block } = this.state;
    if (blockId) {
      block = await this.props.retrieveQuestionBlock(blockId);
    } else {
      block = { survey: this.props.survey.id };
    }
    this.setState({ blockModalOpen: true, block, blockActionType });
  };

  handleQuestionModalClose = () =>
    this.setState({ questionModalOpen: false, question: null, questionActionType: '' });

  handleBlockModalClose = () =>
    this.setState({ blockModalOpen: false, block: null, blockActionType: '' });

  handleSurveyModalOpen = () => this.setState({ surveyModalOpen: true });

  handleSurveyModalClose = () => this.setState({ surveyModalOpen: false });

  generateId = (question) => `${question.question_type}-${question.id}`;

  handleQuestionFormSubmit = (data) => {
    const { questionActionType } = this.state;
    switch (questionActionType) {
      case 'Add':
        return this.handleQuestionAddFormSubmit(data);
      case 'Edit':
        return this.handleQuestionEditFormSubmit(data);
      case 'Delete':
        return this.handleQuestionDeleteFormSubmit();
      default:
    }
  };

  handleQuestionAddFormSubmit = (values) => {
    Question.post(values)
      .then((data) => {
        this.props.questionDetailSuccess(data);
        this.props.questionBlockQuestionAdded(data.id, data.question_block);
        this.setState({ questionModalOpen: false, question: null, questionActionType: '' });
        notifySuccess('Question added');
      })
      .catch(notifyError);
  };

  handleQuestionEditFormSubmit = (values) => {
    const { question } = this.state;
    Question.patch(question.id, values)
      .then((data) => {
        this.props.questionDetailSuccess(data);
        if (question.number !== data.number) {
          this.props.questionBlockDetail(question.question_block);
        }
        this.setState({ questionModalOpen: false, question: null, questionActionType: '' });
        notifySuccess(`Question #${data.number} saved`);
      })
      .catch(notifyError);
    if (question.question_block !== values.question_block) {
      this.props.questionBlockQuestionMoved(
        question.id,
        question.question_block,
        values.question_block
      );
    }
  };

  handleQuestionDeleteFormSubmit = async () => {
    const { question } = this.state;
    const ok = await this.props.deleteQuestion(question.id, question.question_block);
    if (ok) {
      notifySuccess(`Question #${question.number} deleted`);
    }
    this.setState({ questionModalOpen: false, question: null, questionActionType: '' });
  };

  handleBlockFormSubmit = (data) => {
    const { blockActionType } = this.state;
    switch (blockActionType) {
      case 'Add':
        return this.handleBlockAddFormSubmit(data);
      case 'Edit':
        return this.handleBlockEditFormSubmit(data);
      case 'Delete':
        return this.handleBlockDeleteFormSubmit();
      default:
    }
  };

  handleBlockAddFormSubmit = (values) => {
    Block.post(values)
      .then((data) => {
        this.props.questionBlockDetailSuccess(data);
        this.props.surveyQuestionBlockAdded(this.props.survey.id, data);
        this.setState({ blockModalOpen: false, block: null, blockActionType: '' });
        notifySuccess(`Question Block '${data.title}' added`);
      })
      .catch(notifyError);
  };

  handleBlockEditFormSubmit = (values) => {
    const { block } = this.state;
    Block.patch(block.id, values)
      .then((data) => {
        this.props.questionBlockDetailSuccess(data);
        this.setState({ blockModalOpen: false, block: null, blockActionType: '' });
        notifySuccess(`Question Block '${data.title}' saved`);
      })
      .catch(notifyError);
  };

  handleBlockDeleteFormSubmit = async () => {
    const { block } = this.state;
    const ok = await this.props.deleteQuestionBlock(block.id);
    if (ok) {
      notifySuccess(`Question Block ${block.title} deleted`);
    }
    this.setState({ blockModalOpen: false, block: null, blockActionType: '' });
  };

  handleSurveyFormSubmit = async (values) => {
    const { survey, updateSurveyThunk } = this.props;
    values.event_id = values.event;
    delete values.event;
    try {
      await updateSurveyThunk(survey.id, values);
      notifySuccess('Survey saved');
    } catch (error) {
      failedAssertion("Survey couldn't be saved", { error });
    }
    this.setState({ surveyModalOpen: false });
  };

  render() {
    const { survey } = this.props;
    if (!survey || survey.isLoading) {
      return <Loading />;
    }
    if (survey.hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Could not retrieve event data.
        </Alert>
      );
    }
    return (
      <div className="survey">
        <div
          className="white-soft-box2 white-soft-box2--no-animate"
          style={{ paddingBottom: '16px' }}
        >
          <SurveyAdminPageHeader survey={survey}>Edit survey</SurveyAdminPageHeader>
        </div>
        <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 mt2 mb2">
          <button
            type="button"
            className="btn btn-xs btn-default pull-right"
            onClick={this.handleSurveyModalOpen}
          >
            <i className="fa fa-pencil icon-space-r" />
            Edit Survey
          </button>
          <button
            type="button"
            className="btn btn-xs btn-default pull-right icon-space-r"
            onClick={() => this.handleBlockActionClick(null, 'Add')}
          >
            <i className="fa fa-plus icon-space-r" />
            New Block
          </button>
        </div>
        <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
          {survey.question_blocks.map((block) => (
            <QuestionBlock
              key={`block-${block.id}`}
              block={block}
              generateId={this.generateId}
              editable
              onQuestionActionClick={this.handleQuestionActionClick}
              onBlockActionClick={this.handleBlockActionClick}
            />
          ))}
        </div>
        {this.state.questionModalOpen && (
          <QuestionModal
            modalOpen={this.state.questionModalOpen}
            onModalClose={this.handleQuestionModalClose}
            onFormSubmit={this.handleQuestionFormSubmit}
            question={this.state.question}
            formType={this.state.questionActionType}
          />
        )}
        {this.state.blockModalOpen && (
          <QuestionBlockModal
            modalOpen={this.state.blockModalOpen}
            onModalClose={this.handleBlockModalClose}
            onFormSubmit={this.handleBlockFormSubmit}
            block={this.state.block}
            formType={this.state.blockActionType}
          />
        )}
        {this.state.surveyModalOpen && (
          <SurveyModal
            modalOpen={this.state.surveyModalOpen}
            onModalClose={this.handleSurveyModalClose}
            onFormSubmit={this.handleSurveyFormSubmit}
            survey={this.props.survey}
          />
        )}
      </div>
    );
  }
}

const makeMapStateToProps = (state, props) => {
  const surveyDetailSelector = makeSurveyDetailSelector();
  const mapStateToProps = (state, props) => ({
    survey: surveyDetailSelector(state, props.match.params.surveyId),
  });
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  {
    surveyDetailThunk,
    updateSurveyThunk,
    retrieveQuestion,
    deleteQuestion,
    retrieveQuestionBlock,
    deleteQuestionBlock,
    questionDetailSuccess,
    questionBlockDetail,
    questionBlockDetailSuccess,
    questionBlockQuestionAdded,
    questionBlockQuestionMoved,
    surveyQuestionBlockAdded,
  }
)(SurveyAdminDetailPage);

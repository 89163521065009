import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { retrieveSurvey } from 'store/actions/surveys';

class QuestionBlockSelector extends React.Component {
  static propTypes = {
    surveyId: PropTypes.number.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    retrieveSurvey: PropTypes.func.isRequired,
  };

  state = {
    blocks: null,
  };

  async componentDidMount() {
    const survey = await this.props.retrieveSurvey(this.props.surveyId);
    this.setState({ blocks: survey.question_blocks });
  }

  render() {
    const { blocks } = this.state;
    if (!blocks) {
      return null;
    }
    const { name, onChange, onBlur, value } = this.props;
    return (
      <select
        className="form-control"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      >
        <option key="nullable-block" value={null}>
          -------
        </option>
        }
        {blocks.map((block) => (
          <option key={block.id} value={block.id}>{`${block.title}`}</option>
        ))}
      </select>
    );
  }
}

export default connect(
  null,
  { retrieveSurvey }
)(QuestionBlockSelector);

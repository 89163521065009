import React from 'react';
import PropTypes from 'prop-types';

export const EditChoiceMatrixMetadata = (props) => {
  return (
    <>
      <div className="flex-row flex-row--space-between">
        <h4>Choice Matrix Questions</h4>
        <div>
          <button
            className="btn btn-sm btn-default"
            type="button"
            onClick={() =>
              props.handleMetadataNew('choiceMatrix.labels', props.metadata, props.setFieldValue)
            }
          >
            <i className="fa fa-fw fa-plus" />
          </button>
        </div>
      </div>
      <div className="clearfix" />
      {props.metadata &&
        props.metadata.choiceMatrix &&
        props.metadata.choiceMatrix.labels &&
        props.metadata.choiceMatrix.labels.map((label, idx) => {
          const labelName = `choiceMatrix.labels-${idx}-label`;
          const labelError =
            props.errors.metadata &&
            props.errors.metadata.choiceMatrix &&
            props.errors.metadata.choiceMatrix.labels &&
            props.errors.metadata.choiceMatrix.labels[idx] &&
            props.errors.metadata.choiceMatrix.labels[idx].text;

          return (
            <div className="row" key={`choiceMatrix-${idx}`}>
              <div className="col-xs-1">
                <div className="form-group">
                  <label className="label-control">&nbsp;</label>
                  <div className="form-control" style={{ border: 'none', boxShadow: 'none' }}>
                    <button
                      type="button"
                      className="btn btn-xs btn-outline-danger"
                      onClick={() =>
                        props.handleMetadataDelete(
                          [idx, 'choiceMatrix.labels'],
                          props.metadata,
                          props.setFieldValue
                        )
                      }
                    >
                      <i className="fa fa-fw fa-trash" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-xs-11">
                <div className="form-group">
                  <label htmlFor={labelName} className="label-control">
                    Q{idx + 1}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={label}
                    name={labelName}
                    onChange={(e) =>
                      props.handleMetadataChange(
                        e,
                        `choiceMatrix.labels[${idx}]`,
                        props.metadata,
                        props.setFieldValue
                      )
                    }
                  />
                  {!!labelError && <span className="help-block">{labelError}</span>}
                </div>
              </div>
            </div>
          );
        })}
      <div className="flex-row flex-row--space-between">
        <h4>Choice Matrix Choices</h4>
        <div>
          <button
            className="btn btn-sm btn-default"
            type="button"
            onClick={() =>
              props.handleMetadataNew('choiceMatrix.choices', props.metadata, props.setFieldValue)
            }
          >
            <i className="fa fa-fw fa-plus" />
          </button>
        </div>
      </div>
      {props.metadata &&
        props.metadata.choiceMatrix &&
        props.metadata.choiceMatrix.choices.map(({ text, value }, idx) => {
          const textName = `choiceMatrix.choices-${idx}-text`;
          const valueName = `choiceMatrix.choices-${idx}-value`;
          const textError =
            props.errors.metadata &&
            props.errors.metadata.choices &&
            props.errors.metadata.choices[idx] &&
            props.errors.metadata.choices[idx].text;
          const valueError =
            props.errors.metadata &&
            props.errors.metadata.choices &&
            props.errors.metadata.choices[idx] &&
            props.errors.metadata.choices[idx].value;
          return (
            <div className="row" key={`choice-${idx}`}>
              <div className="col-xs-1">
                <div className="form-group">
                  <label className="label-control">&nbsp;</label>
                  <div className="form-control" style={{ border: 'none', boxShadow: 'none' }}>
                    <button
                      type="button"
                      className="btn btn-xs btn-outline-danger"
                      onClick={() =>
                        props.handleMetadataDelete(
                          [idx, 'choiceMatrix.choices'],
                          props.metadata,
                          props.setFieldValue
                        )
                      }
                    >
                      <i className="fa fa-fw fa-trash" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-xs-11 col-md-5">
                <div className={`form-group ${textError ? 'has-error' : ''}`}>
                  <label htmlFor={textName} className="label-control">
                    Choice {idx + 1} Text
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={text}
                    name={textName}
                    onChange={(e) =>
                      props.handleMetadataChange(
                        e,
                        `choiceMatrix.choices[${idx}].text`,
                        props.metadata,
                        props.setFieldValue
                      )
                    }
                  />
                  {textError && <span className="help-block">{textError}</span>}
                </div>
              </div>
              <div className="col-xs-11 col-md-6">
                <div className={`form-group ${valueError ? 'has-error' : ''}`}>
                  <label htmlFor={valueName} className="label-control">
                    Choice {idx + 1} Value
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={value}
                    name={valueName}
                    onChange={(e) =>
                      props.handleMetadataChange(
                        e,
                        `choiceMatrix.choices[${idx}].value`,
                        props.metadata,
                        props.setFieldValue
                      )
                    }
                  />
                  {valueError && <span className="help-block">{valueError}</span>}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

EditChoiceMatrixMetadata.propTypes = {
  metadata: PropTypes.object,
  errors: PropTypes.object,
  handleMetadataNew: PropTypes.func.isRequired,
  handleMetadataChange: PropTypes.func.isRequired,
  handleMetadataDelete: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

export const EditChoiceMetadata = (props) => {
  return (
    <>
      <div className="pull-right">
        <button
          className="btn btn-sm btn-default"
          type="button"
          onClick={() => props.handleMetadataNew('choices', props.metadata, props.setFieldValue)}
        >
          <i className="fa fa-fw fa-plus" />
        </button>
      </div>
      <div className="clearfix" />
      {props.metadata &&
        props.metadata.choices &&
        props.metadata.choices.map(({ text, value }, idx) => {
          const textName = `choices-${idx}-text`;
          const valueName = `choices-${idx}-value`;
          const textError =
            props.errors.metadata &&
            props.errors.metadata.choices &&
            props.errors.metadata.choices[idx] &&
            props.errors.metadata.choices[idx].text;
          const valueError =
            props.errors.metadata &&
            props.errors.metadata.choices &&
            props.errors.metadata.choices[idx] &&
            props.errors.metadata.choices[idx].value;
          return (
            <div className="row" key={`choice-${idx}`}>
              <div className="col-xs-1">
                <div className="form-group">
                  <label className="label-control">&nbsp;</label>
                  <div className="form-control" style={{ border: 'none', boxShadow: 'none' }}>
                    <button
                      type="button"
                      className="btn btn-xs btn-outline-danger"
                      onClick={() =>
                        props.handleMetadataDelete(
                          [idx, 'choices'],
                          props.metadata,
                          props.setFieldValue
                        )
                      }
                    >
                      <i className="fa fa-fw fa-trash" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-xs-11 col-md-5">
                <div className={`form-group ${textError ? 'has-error' : ''}`}>
                  <label htmlFor={textName} className="label-control">
                    Choice {idx + 1} Text
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={text}
                    name={textName}
                    onChange={(e) =>
                      props.handleMetadataChange(
                        e,
                        `choices[${idx}].text`,
                        props.metadata,
                        props.setFieldValue
                      )
                    }
                  />
                  {textError && <span className="help-block">{textError}</span>}
                </div>
              </div>
              <div className="col-xs-11 col-md-6">
                <div className={`form-group ${valueError ? 'has-error' : ''}`}>
                  <label htmlFor={valueName} className="label-control">
                    Choice {idx + 1} Value
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={value}
                    name={valueName}
                    onChange={(e) =>
                      props.handleMetadataChange(
                        e,
                        `choices[${idx}].value`,
                        props.metadata,
                        props.setFieldValue
                      )
                    }
                  />
                  {valueError && <span className="help-block">{valueError}</span>}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

EditChoiceMetadata.propTypes = {
  metadata: PropTypes.object,
  errors: PropTypes.object,
  handleMetadataNew: PropTypes.func.isRequired,
  handleMetadataChange: PropTypes.func.isRequired,
  handleMetadataDelete: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
